import { useLocalStore } from '../../stores'
import { Moon, Sun, SunMoon, type LucideIcon } from 'lucide-react'
import type { ReactNode } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@bpinternal/ui-kit-next'

type Props = { className?: string; children?: ReactNode; asChild?: boolean }

export const AppearanceDropdown = ({ children, asChild = true }: Props) => {
  const darkMode = useLocalStore((s) => s.theme)
  const setDarkMode = useLocalStore((s) => s.setTheme)

  const items: { icon: LucideIcon; label: string; value: 'dark' | 'light' | 'auto' }[] = [
    {
      icon: Moon,
      label: 'Dark',
      value: 'dark',
    },
    {
      icon: Sun,
      label: 'Light',
      value: 'light',
    },
    {
      icon: SunMoon,
      label: 'Auto',
      value: 'auto',
    },
  ]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild={asChild}>{children}</DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          {items.map(({ icon: Icon, label, value }) => (
            <DropdownMenuItem
              disabled={darkMode === value}
              key={value}
              onClick={() => {
                setDarkMode(value)
              }}
            >
              {Icon && <Icon />}
              <span>{label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
