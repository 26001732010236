import { Message, User } from '@botpress/client'
import type { MessageObject } from '@botpress/webchat@2.3.7'
import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import { getApiClientForBot } from '~/client'
import { MessageSchema } from '~/features/conversations/webchat/adapters'
import { useQueryKeys } from '../useQueryKeys'
import { cacheDuration } from '~/shared'
import { listConversationUsersQueryOptions } from '~/queries'

export const useWebchatMessages = ({
  workspaceId,
  botId,
  conversationId,
}: {
  workspaceId: string
  botId: string
  conversationId: string
}) => {
  const { listMessages } = useQueryKeys()
  const { users } = useSuspenseQuery(listConversationUsersQueryOptions({ conversationId, botId, workspaceId })).data

  return useSuspenseInfiniteQuery({
    staleTime: cacheDuration.short,
    queryKey: listMessages(workspaceId, botId, conversationId),
    queryFn: async ({ pageParam }) => {
      const { messages, meta } = await getApiClientForBot({ workspaceId, botId })
        .listMessages({ conversationId, nextToken: pageParam })
        .catch((e) => {
          console.error('Failed to fetch events', e)
          return { messages: [], meta: { nextToken: null } }
        })
      return {
        messages: parseMessages(messages, users, {}),
        meta,
      }
    },
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
  })
}

//TODO: Fix config typing
function parseMessages(messages: Message[], users: User[], config: any) {
  const parsedMessages: (MessageObject & { id: string })[] = []
  for (const message of messages) {
    try {
      const payload = MessageSchema.parse(message)
      const user = users.find((u) => u.id === message.userId)
      parsedMessages.push({
        sender:
          message.direction === 'incoming'
            ? {
                name: user?.name ?? user?.id ?? 'User',
                avatar: user?.pictureUrl,
              }
            : { name: config?.botName ?? 'Bot', avatar: config?.botAvatar ?? '' },
        timestamp: new Date(message.createdAt),
        block: payload.block,
        disableInput: true,
        direction: message.direction,
        id: message.id,
      })
    } catch (err) {
      const jsonString = JSON.stringify(message.payload, null, 2)
      const markdownString = `\`\`\`json\n${jsonString}\n\`\`\``
      const user = users.find((u) => u.id === message.userId)
      parsedMessages.push({
        sender:
          message.direction === 'incoming'
            ? {
                name: user?.name ?? user?.id ?? 'User',
                avatar: user?.pictureUrl,
              }
            : { name: config?.botName ?? 'Bot', avatar: config?.botAvatar ?? '' },
        timestamp: new Date(message.createdAt),
        block: {
          type: 'bubble',
          block: {
            type: 'text',
            text: `_unsupported message type_ \n ${markdownString}`,
          },
        },
        disableInput: true,
        direction: message.direction,
        id: message.id,
      })
    }
  }
  return parsedMessages
}
