type DailyUsage = {
  value: number
  date: string
}
export const mergeDailyUsages: (usage1: DailyUsage[], usage2: DailyUsage[]) => DailyUsage[] = (usage1, usage2) => {
  const mergedUsage = new Map()

  // Combine both usage arrays and process them in a single loop
  ;[...usage1, ...usage2].forEach(({ date, value }) => {
    mergedUsage.set(date, (mergedUsage.get(date) || 0) + value)
  })

  // Convert the map back to an array
  return Array.from(mergedUsage, ([date, value]) => ({ date, value }))
}

/**
 * Ensures data exists for every day from the start of the month up to either:
 * - The current day (if viewing the current month)
 * - The last day (if viewing a past month)
 *
 * This function:
 * 1. Takes existing data points and preserves their values
 * 2. Fills in missing dates with zero values
 * 3. Does not add data for future dates
 * 4. Returns a chronologically sorted array with one entry per day
 *
 * This is useful for charts to ensure a continuous date range is displayed
 * with appropriate values, even when the original data has gaps.
 *
 * @param data The original sparse data array with dates and values
 * @param selectedMonth The month being displayed
 * @returns A complete array of data points for the selected month up to the current day
 */
export const fillMissingDates = (data: { date: string; value: number }[], selectedMonth: Date) => {
  const filledData: { date: string; value: number }[] = []
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Reset time component for accurate comparison

  // Calculate the last day to include (either today or end of month)
  const isCurrentMonth =
    today.getMonth() === selectedMonth.getMonth() && today.getFullYear() === selectedMonth.getFullYear()

  // If it's the current month, we only go up to today
  // Otherwise, we include the entire month
  const lastDayOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate()
  const lastDay = isCurrentMonth ? Math.min(today.getDate(), lastDayOfMonth) : lastDayOfMonth

  // Fill data from day 1 to the calculated last day
  for (let day = 1; day <= lastDay; day++) {
    const date = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), day)
      .toISOString()
      .split('T')[0] as string
    const existingEntry = data.find((entry) => entry.date === date)
    filledData.push(existingEntry || { date, value: 0 })
  }

  return filledData
}

export function addDailyAverage(
  data: { date: string; value?: number; dailyAverage?: number }[],
  selectedMonth: Date,
  fillValue: number
): { date: string; value?: number; dailyAverage?: number }[] {
  // get the last day of the month
  const dataCopy = [...data]
  const lastDayOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate()

  for (let day = 1; day <= lastDayOfMonth; day++) {
    const date = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), day)
      .toISOString()
      .split('T')[0] as string

    // Check if date already exists in the array
    const existingIndex = dataCopy.findIndex((item) => item.date === date)

    if (existingIndex !== -1) {
      // Update existing entry with projected value
      dataCopy[existingIndex] = {
        ...dataCopy[existingIndex],
        date: date, // Explicitly include the date to satisfy type requirements
        dailyAverage: fillValue,
      }
    } else {
      // Add new entry if date doesn't exist
      dataCopy.push({ date, dailyAverage: fillValue })
    }
  }

  return dataCopy
}

type Breakdown = {
  botId: string
  value: number
  name?: string
}
/**
 * Segregates an array of breakdowns into top n breakdowns and a sum of the rest.
 *
 * @param breakdowns - The array of Breakdown objects to segregate
 * @param n - The number of top breakdowns to keep (default: 3)
 * @returns An object containing:
 *   - usages: Array of the top n breakdowns by value
 *   - remainer: Sum of all remaining breakdowns' values, or undefined if no remaining usage
 */
export function segregateUsages(
  breakdowns: Breakdown[],
  n = 3
): {
  usages: Breakdown[]
  remainder?: number
} {
  const sortedBreakdowns = [...breakdowns].sort((a, b) => b.value - a.value)

  const usages = sortedBreakdowns.slice(0, n)
  const remainder =
    sortedBreakdowns.length > 3 ? sortedBreakdowns.slice(n).reduce((acc, { value }) => acc + value, 0) : undefined

  return {
    usages,
    remainder,
  }
}
