import { quotaTypes } from '@bpinternal/const'
import { Flex, Grid, HoverCard, Separator, Text } from '@radix-ui/themes'
import { useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { ClientOutputs } from '@botpress/client'
import { partition } from 'lodash'
import { useMemo, useState } from 'react'
import { HiListBullet, HiMiniPlus, HiOutlineClock, HiOutlineMagnifyingGlass, HiOutlinePlus } from 'react-icons/hi2'
import EmptyBotsIcon from '~/assets/ai-30.svg?react'
import { Boundary, Page, UsageProgressBar, UserAvatar } from '~/componentsV2'
import { Avatar, EmptyState, History, Input, Link, Icon } from '~/elementsv2'
import { auditMessages } from '~/features/audits/components'
import { Action } from '~/features/audits/types'
import { ContributionsSidebarSection, ViewAsSection, WorkspaceSocialHeader } from '~/features/workspaces/components'
import { useCreateBot, useRecentAudits, useUsage } from '~/hooks'
import { SidebarLayout } from '~/layoutsV2'
import { workspacesQueryOptions } from '~/queries'
import { computeBotHistory } from '~/features/bots/services'
import { DYNAMIC_QUOTAS } from '~/features/usage/constants'
import { userPreferencesQueryOptions } from '~/queries/user'
import { getQueryOptions, useQuery, useSuspenseQuery } from '~/services'
import { BotPreviewCard } from '~/features/bots/components'
import { Card, Button } from '@bpinternal/ui-kit'
import { createBotPrompt } from '~/features/bots/services/createBotPrompt'

export const Route = createFileRoute('/workspaces/$workspaceId/home')({
  component: WorkspaceComponent,
})

const BOTS_PER_PAGE = 10

function WorkspaceComponent() {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const { data: workspaces } = useTanstackSuspenseQuery(workspacesQueryOptions())

  return (
    <Page title={<WorkspaceSocialHeader workspace={workspace} />}>
      <SidebarLayout
        main={
          <Boundary showErrorIcon errorIconSize={5}>
            <BotList />
          </Boundary>
        }
        rightSidebar={
          <>
            <Boundary className="h-48" loaderSize={'7'}>
              <ViewAsSection currentRoute={Route.id} userWorkspaces={workspaces} />
            </Boundary>
            <Boundary className="h-48" loaderSize={'7'}>
              <UsagePreview count={4} />
              <Separator size={'4'} mt={'6'} mb={'4'} />
            </Boundary>
            <Boundary className="h-80" loaderSize={'7'}>
              <AuditsPreview />
              <Separator size={'4'} mt={'6'} mb={'4'} />
            </Boundary>
            <Boundary className="h-80" loaderSize={'7'}>
              <ContributionsSidebarSection userWorkspaces={workspaces} workspace={workspace} />
              <Separator size={'4'} mt={'6'} mb={'4'} />
            </Boundary>
            <Boundary className="h-24" loaderSize={'7'}>
              <MembersPreview />
            </Boundary>
          </>
        }
      />
    </Page>
  )
}

const BotList = () => {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const { mutate: createBot, isPending } = useCreateBot(workspace.id)
  const [filter, setFilter] = useState('')
  const [nBots, setNBots] = useState(BOTS_PER_PAGE)
  const prefsProps = { path: '$workspaceId/botHistory', params: { workspaceId: workspace.id } } as const
  const { user } = Route.useRouteContext()

  const { data: bots = [] } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: workspace.id })
  const recentBots = useTanstackSuspenseQuery(userPreferencesQueryOptions(prefsProps)).data.filter(
    (b) => !!bots.find((bot) => bot.id === b)
  )
  const botHistory = useMemo(() => computeBotHistory(recentBots), [recentBots])

  if (bots.length === 0) {
    return (
      <Card className="py-2">
        <EmptyState
          icon={EmptyBotsIcon}
          className="h-80"
          title="Workspace has no bots"
          description="Your workspace has no bots. Get started by creating a new bot"
          primaryAction={
            <Button
              color="grass"
              leading={<HiMiniPlus size={20} />}
              onClick={() => void createBotPrompt().then(createBot)}
              loading={isPending}
              size="3"
            >
              Create Bot
            </Button>
          }
        />
      </Card>
    )
  }

  return (
    <Flex direction={'column'} gap={'4'} className="@container">
      {botHistory.length > 0 && (
        <Flex direction={'column'} gap={'2'}>
          <Flex align={'center'} gap={'1'}>
            <Icon icon={HiOutlineClock} color="gray" />
            <Text size={'2'} weight={'regular'}>
              Recent
            </Text>
          </Flex>
          <Grid gap={'2'} className="grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
            {botHistory.map((botId) => (
              <BotPreviewCard
                showChart={false}
                key={botId}
                workspaceId={workspaceId}
                userId={user.id}
                location="recent_bots"
                id={botId}
              />
            ))}
          </Grid>
        </Flex>
      )}
      <Flex direction={'column'} gap={'2'}>
        {botHistory.length > 0 && (
          <Flex align={'center'} gap={'1'}>
            <Icon icon={HiListBullet} color="gray" />
            <Text size={'2'} weight={'regular'}>
              Bots
            </Text>
          </Flex>
        )}
        <Flex width={'100%'} gap={'2'}>
          <Input
            leading={<HiOutlineMagnifyingGlass />}
            placeholder="Find a bot"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Button
            loading={isPending}
            leading={<HiOutlinePlus strokeWidth={'3'} />}
            onClick={() => void createBotPrompt().then(createBot)}
          >
            Create Bot
          </Button>
        </Flex>
      </Flex>
      <Grid gap={'2'} className="grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
        {bots
          .filter((b) => b.name.toLowerCase().includes(filter.toLowerCase()))
          .slice(0, nBots)
          .map((bot) => (
            <BotPreviewCard
              key={bot.id}
              workspaceId={workspaceId}
              className="h-60"
              userId={user.id}
              location="all_bots"
              {...bot}
            />
          ))}
        <Button
          variant="dashed"
          className="h-60"
          loading={isPending}
          onClick={() => void createBotPrompt().then(createBot)}
          size={'3'}
          leading={<HiOutlinePlus strokeWidth={'2'} />}
        >
          Create Bot
        </Button>
      </Grid>
      {bots.length > nBots && (
        <Button variant="ghost" className="mx-auto w-fit" onClick={() => setNBots((n) => n + BOTS_PER_PAGE)}>
          Load more
        </Button>
      )}
    </Flex>
  )
}

const AuditsPreview = ({ count = 3 }) => {
  const { workspaceId } = Route.useParams()
  const audits = useRecentAudits(workspaceId).data.slice(0, count)
  const historyItems = audits.map((audit, index) => {
    return {
      timelinePointElement: audit.userEmail && (
        <HoverCard.Root>
          <HoverCard.Trigger>
            <UserAvatar size={'1'} userId={audit.userId ?? undefined} workspaceId={workspaceId} />
          </HoverCard.Trigger>
          <HoverCard.Content side="top">
            <Flex gap={'2'} align={'center'}>
              <UserAvatar size={'2'} userId={audit.userId ?? undefined} workspaceId={workspaceId} />
              <Text size={'2'} weight={'medium'}>
                {audit.userEmail}
              </Text>
            </Flex>
          </HoverCard.Content>
        </HoverCard.Root>
      ),
      content: (
        <Flex direction={'column'} gap="4">
          <Text className="text-xs">
            {(auditMessages[audit.action as Action] ?? auditMessages.UNKNOWN).render(audit)}
          </Text>
          {index === audits.length - 1 ? (
            <Link size={'1'} to="/workspaces/$workspaceId/settings/audits" params={{ workspaceId }}>
              View all audits
            </Link>
          ) : null}
        </Flex>
      ),
      timestamp: audit.recordedAt,
    }
  })

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight={'medium'}>
        Recent changes
      </Text>
      <History items={historyItems} />
    </Flex>
  )
}

const MembersPreview = ({ count = 6 }) => {
  const { workspaceId } = Route.useParams()
  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data

  const shownMemebers = workspaceMembers.slice(0, count)
  const hiddenMembers = workspaceMembers.slice(count)

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight={'medium'}>
        Members
      </Text>
      <Flex gap={'2'} wrap={'wrap'}>
        {shownMemebers?.map((member) => (
          <HoverCard.Root key={member.id}>
            <HoverCard.Trigger>
              <UserAvatar
                className="h-8 w-8 cursor-pointer text-base"
                userId={member.userId ?? undefined}
                workspaceId={workspaceId}
              />
            </HoverCard.Trigger>
            <HoverCard.Content side="top">
              <Flex gap={'2'}>
                <Avatar className="h-9 w-9 text-base" name={member.email} />
                <Flex direction={'column'}>
                  <Text size={'2'} weight={'medium'}>
                    {member.email}
                  </Text>
                  <Text size={'1'} color={'gray'}>
                    {member.role}
                  </Text>
                </Flex>
              </Flex>
            </HoverCard.Content>
          </HoverCard.Root>
        ))}
      </Flex>
      <Link size={'1'} to="/workspaces/$workspaceId/settings/members" params={{ workspaceId }}>
        {hiddenMembers.length === 0 ? 'View all members' : `+ ${hiddenMembers.length} more members`}
      </Link>
    </Flex>
  )
}

const UsagePreview = ({ count }: { count: number }) => {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data

  // We don't want to show these quotas in the UI
  const excludedQuotas: ClientOutputs['getUsage']['usage']['type'][] = ['bing_search_spend', 'openai_spend']

  const usagesRecord = useUsage({
    workspaceId: workspace.id,
    quotas: [...DYNAMIC_QUOTAS, ...quotaTypes].filter((q) => !excludedQuotas.includes(q)),
  })

  const usages = [...Object.values(usagesRecord).filter((usage) => usage.value !== 0)].sort(
    (a, b) => b.percentage - a.percentage
  )

  const [highDynamicUsage, restUsages] = partition(
    usages,
    (usage) => DYNAMIC_QUOTAS.includes(usage.type as any) && usage.percentage > 50
  )

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight={'medium'}>
        Usages
      </Text>
      {usages.length > 0 ? (
        <>
          <Flex direction={'column'} gap="2" pr="4">
            {highDynamicUsage.map((usage) => (
              <UsageProgressBar key={usage.type} {...usage} />
            ))}
            {restUsages.slice(0, count - highDynamicUsage.length).map((usage) => (
              <UsageProgressBar key={usage.type} {...usage} />
            ))}
          </Flex>

          <Link size={'1'} to="/workspaces/$workspaceId/usage" params={{ workspaceId: workspace.id }}>
            View all usages
          </Link>
        </>
      ) : (
        <Text size={'1'} className="text-gray-11">
          {workspace.name} has no usage.
          <br />
          <br /> Start building and using bots, to see overview of your billing limits usage here.
        </Text>
      )}
    </Flex>
  )
}
