import { ComponentProps, ReactNode, forwardRef } from 'react'
import { EmptyState } from '~/elementsv2'
import { QuotaType } from '@bpinternal/const'
import { formatValueWithUnit } from '~/features/usage/helpers'
import type { Usage } from '@botpress/client'
import { BotSummary } from '~/features/bots/types'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '~/features/usage/constants'
import { cn } from '~/utils'
import InvocationEmptyStateIcon from '~/assets/programming-04.svg?react'
import FileEmptyStateIcon from '~/assets/data-analytics-01.svg?react'
import VectorEmptyStateIcon from '~/assets/data-analytics-04.svg?react'
import TableEmptyStateIcon from '~/assets/data-analytics-14.svg?react'
import AiSpendEmptyStateIcon from '~/assets/data-analytics-15.svg?react'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  type ChartConfig,
} from '@bpinternal/ui-kit-next'
import { Label, Pie, PieChart } from 'recharts'
import { BotBreakdownLegend } from './BotBreakdownLegend'
import { segregateUsages } from '../utils'

type DetailedUsage = Usage & {
  breakdown: ({
    botId: string
    value: number
  } & Partial<BotSummary>)[]
}
const valueFormatter = (type: QuotaType) => (value: number) => formatValueWithUnit(value, type, 0)
const topN = 3

const getUsageEmptyStateIcon = (type: QuotaType) => {
  switch (type) {
    case 'ai_spend':
      return AiSpendEmptyStateIcon
    case 'storage_count':
      return FileEmptyStateIcon
    case 'table_row_count':
      return TableEmptyStateIcon
    case 'knowledgebase_vector_storage':
      return VectorEmptyStateIcon
    case 'invocation_calls':
      return InvocationEmptyStateIcon
    default:
      return InvocationEmptyStateIcon
  }
}

type UsageCardProps = {
  usage: DetailedUsage
  type: QuotaType
  size?: 'sm' | 'lg'
  description?: ReactNode
} & ComponentProps<typeof Card>
export const BreakdownByBotCard = forwardRef<HTMLDivElement, UsageCardProps>(
  ({ usage, type, size = 'sm', className, description, ...props }, ref) => {
    const { usages, remainder } = segregateUsages(usage.breakdown, topN)
    const chartData = usages.map((usage) => ({
      ...usage,
      fill: `var(--color-${usage.botId})`,
    }))

    if (remainder !== undefined) {
      chartData.push({
        value: remainder,
        name: 'Other',
        botId: 'other',
        fill: 'var(--color-other)',
      })
    }

    if (usage.quota > usage.value) {
      chartData.push({
        value: usage.quota - usage.value,
        name: 'Remaining',
        botId: 'remaining',
        fill: 'var(--color-remaining)',
      })
    }

    const chartConfig = chartData.reduce<ChartConfig>(
      (acc, { botId, name }, i) => ({
        ...acc,
        [botId]: { label: name, color: botId === 'remaining' ? 'hsl(var(--secondary))' : `hsl(var(--chart-${i + 1}))` }, //Very ugly but will do the trick for now
      }),
      {}
    )

    return (
      <Card {...props} ref={ref} className={cn('flex flex-col @container', className)}>
        <CardHeader>
          <CardTitle>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].name}</CardTitle>
          <CardDescription>
            {description ? description : QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].description}
          </CardDescription>
        </CardHeader>
        <CardContent className={cn('mt-auto', { 'mb-auto': usage.breakdown.length === 0 })}>
          {usage.breakdown.length === 0 ? (
            <EmptyState
              gap={'2'}
              icon={getUsageEmptyStateIcon(usage.type)}
              description="No usage data available for this period."
              descriptionSize="1"
              className="grow self-center"
            />
          ) : (
            <div className={cn('flex grow flex-col items-center gap-4 @xs:flex-row')}>
              <ChartContainer
                config={chartConfig}
                className={cn('mx-auto aspect-square max-h-[250px]', size === 'lg' ? 'size-52' : 'size-36')}
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel valueFormatter={valueFormatter(type) as any} />}
                  />
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="botId"
                    innerRadius={size === 'lg' ? 60 : 42}
                    strokeWidth={5}
                  >
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                          return (
                            <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className={cn('fill-foreground font-bold', {
                                  'text-xl': size === 'lg',
                                  'text-base': size === 'sm',
                                })}
                              >
                                {`${formatValueWithUnit(usage.value, type)}`}
                              </tspan>
                              <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className={cn('fill-muted-foreground')}>
                                {`of ${formatValueWithUnit(usage.quota, type)}`}
                              </tspan>
                            </text>
                          )
                        }
                      }}
                    />
                  </Pie>
                </PieChart>
              </ChartContainer>
              <BotBreakdownLegend
                topN={topN}
                breakdowns={usage.breakdown}
                valueFormatter={(value: number) => formatValueWithUnit(value, type)}
              />
            </div>
          )}
        </CardContent>
      </Card>
    )
  }
)
BreakdownByBotCard.displayName = 'BreadownByBotCard'
