import { useParams, useRouter } from '@tanstack/react-router'
import type { ClientOutputs, Table } from '@botpress/client'
import { TableIcon } from 'lucide-react'
import type { ReactNode } from 'react'
import { DropdownMenu, Icon, type MenuItem } from '~/elementsv2'

type Props = { className?: string; tables: Table[]; children: ReactNode }

export const TableSelectorMenu = ({ tables, ...props }: Props) => {
  const navigate = useRouter().navigate
  const { workspaceId, botId } = useParams({ strict: false })

  if (!workspaceId || !botId) {
    return null
  }

  const tableMenuItems: MenuItem[] = [
    {
      type: 'radioGroup',
      value: botId,
      items:
        tables?.map(({ id, name, tags }) => ({
          content: name,
          type: 'radioItem',
          value: id,
          onSelect: () =>
            navigate({
              to: '/workspaces/$workspaceId/bots/$botId/tables/$tableId',
              params: { workspaceId, botId, tableId: id },
              search: { limit: 50, offset: 0 },
            }),
          leadingIcon: <Icon icon={TableIcon} color={tags?.['system'] === 'true' ? 'gray' : 'grass'} variant="soft" />,
        })) ?? [],
    },
  ]
  return <DropdownMenu {...props} disabled={!tables.length} variant="soft" color="gray" content={tableMenuItems} />
}
