import { useParams, useRouter } from '@tanstack/react-router'
import type { ClientOutputs } from '@botpress/client'
import type { ReactNode } from 'react'
import { BotIcon } from '~/componentsV2/BotIcon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@bpinternal/ui-kit-next'

type Props = { className?: string; bots: ClientOutputs['listBots']['bots']; children: ReactNode; asChild?: boolean }

export const BotsDropdown = ({ bots, children, asChild = true }: Props) => {
  const navigate = useRouter().navigate
  const { workspaceId, botId } = useParams({ strict: false })

  if (!workspaceId) {
    return null
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild={asChild}>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-60 max-w-72">
        <DropdownMenuLabel>Bots</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {bots?.map((bot) => {
          return (
            <DropdownMenuItem
              key={bot.id}
              disabled={bot.id === botId}
              onClick={() =>
                navigate({ to: '/workspaces/$workspaceId/bots/$botId', params: { workspaceId, botId: bot.id } })
              }
            >
              <BotIcon id={bot.id} size={'2'} />
              <span className="truncate">{bot.name}</span>
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
