/* eslint-disable @typescript-eslint/no-explicit-any */
import { getApiClient, getApiClientForBot, getGenericClient } from '~/client'
import { getPreference, type GetPreferenceProps, type Preferences } from './preferences'
import {
  queryOptions,
  useQuery as useTanstackQuery,
  useSuspenseQuery as useTanstackSuspenseQuery,
} from '@tanstack/react-query'
import { cacheDuration } from '~/shared'
import {
  getSubscription,
  getSubscriptions,
  getSubscriptionSchedule,
  listWorkspaceMembers,
  listWorkspaces,
} from '~/features/workspaces/services'
import { listBots } from '~/features/bots/services'
import { listAll } from '~/utils'
import semver from 'semver'
import type { Flatten, PathParams } from '~/services/types'
import type { Client } from '@botpress/client'
import { BillingApiClient, PromoCode, BillingInfo } from '~/features/billing/services'
import _ from 'lodash'
import axios from 'axios'
import type { QuotaType } from '@bpinternal/const'

type FlattenedKeys = Flatten<typeof queryKeys>

/**
 * This is where we define query keys, keys are created by using the path of leaf nodes. To include a parameter in the key, use $ before the parameter name.
 * If you want to include a parent node in the key, use _ after the parent node name.
 * When a key is added, you will get a type error if you don't implement the corresponding query function in the queryFunctions object.
 */
const queryKeys = {
  preferences: {
    $path: null,
  },
  workspaces_: {
    $workspaceId_: {
      bots_: {
        $botId_: {
          webchat: null,
          conversations: {
            $integrationName: { $tags: { $participantIds: { all: null } } },
          },
          $conversationId_: {},
          files: { $ids: { $tags: { all: null } } },
          tables: {
            $table_: {
              rows: {
                $filter: { $limit: { $group: { $offset: { $orderBy: { $orderDirection: { $search: null } } } } } },
              },
            },
            $tags: { all: null },
          },
          $userId_: {},
        },
      },
      members: null,
      promoCode: null,
      promoCodeSearch: {
        $query: null,
      },
      billingInfo: null,
      subscription: null,
      subscriptions: null,
      subscriptionSchedule: null,
      usage: {
        activities: {
          $type: {
            $startDate: { $endDate: null },
            daily: {
              $startDate: { $endDate: null },
            },
          },
        },
      },
    },
  },
  integrations_: { $integrationName: { versions: null } },
  fetch: { $url: null },
} as const

export const queryFunctions = {
  'workspaces_/$workspaceId_/bots_/$botId_/conversations/$integrationName/$tags/$participantIds/all': async ({
    botId,
    workspaceId,
    integrationName,
    participantIds,
    tags,
  }: {
    botId: string
    workspaceId: string
    participantIds?: string[]
    tags?: Record<string, string>
    integrationName?: string
  }) =>
    getApiClientForBot({ workspaceId, botId }).list.conversations({ integrationName, participantIds, tags }).collect(),
  'preferences/$path': <U extends keyof Preferences>(props: GetPreferenceProps<U>) => getPreference(props),
  workspaces_: listWorkspaces,
  'workspaces_/$workspaceId_': ({ workspaceId }: { workspaceId: string }) =>
    getApiClient(workspaceId).getWorkspace({ id: workspaceId }),
  'workspaces_/$workspaceId_/members': ({ workspaceId }: { workspaceId: string }) => listWorkspaceMembers(workspaceId),
  'workspaces_/$workspaceId_/subscription': ({ workspaceId }: { workspaceId: string }) => getSubscription(workspaceId),
  'workspaces_/$workspaceId_/subscriptions': ({ workspaceId }: { workspaceId: string }) =>
    getSubscriptions(workspaceId),
  'workspaces_/$workspaceId_/subscriptionSchedule': ({ workspaceId }: { workspaceId: string }) =>
    getSubscriptionSchedule(workspaceId),
  'workspaces_/$workspaceId_/billingInfo': async ({
    workspaceId,
  }: {
    workspaceId: string
  }): Promise<BillingInfo | null> => {
    const resp = await BillingApiClient.getBillingInfo(workspaceId)
    if (_.isEmpty(resp)) {
      return null
    }
    return resp as BillingInfo
  },
  'workspaces_/$workspaceId_/bots_': ({ workspaceId }: { workspaceId: string }) => listBots(workspaceId),
  'workspaces_/$workspaceId_/bots_/$botId_': async ({ botId, workspaceId }: { botId: string; workspaceId: string }) =>
    (await getApiClient(workspaceId).getBot({ id: botId })).bot,
  'workspaces_/$workspaceId_/bots_/$botId_/files/$ids/$tags/all': async ({
    workspaceId,
    botId,
    tags,
    ids,
  }: {
    ids?: string[]
    botId: string
    workspaceId: string
    tags?: Record<string, string>
  }) => listAll(getApiClientForBot({ workspaceId, botId }).listFiles, { tags, ids }, ({ files }) => files),
  'workspaces_/$workspaceId_/bots_/$botId_/$conversationId_': async ({
    workspaceId,
    botId,
    conversationId,
  }: {
    botId: string
    conversationId: string
    workspaceId: string
  }) => getApiClientForBot({ workspaceId, botId }).getConversation({ id: conversationId }),
  'workspaces_/$workspaceId_/bots_/$botId_/$userId_': async ({
    workspaceId,
    botId,
    userId,
  }: {
    botId: string
    userId: string
    workspaceId: string
  }) => getApiClientForBot({ workspaceId, botId }).getUser({ id: userId }),
  'workspaces_/$workspaceId_/bots_/$botId_/webchat': async ({
    workspaceId,
    botId,
    type,
  }: {
    workspaceId: string
    botId: string
    type: Parameters<Client['getBotWebchat']>[0]['type']
  }) => {
    const script = (await getApiClient(workspaceId).getBotWebchat({ id: botId, type })).code
    return script
  },
  integrations_: () =>
    listAll(getGenericClient().listPublicIntegrations, { version: 'latest' }, (res) => res.integrations),
  'integrations_/$integrationName/versions': ({ integrationName }: { integrationName: string }) =>
    getGenericClient()
      .listPublicIntegrations({ name: integrationName })
      .then((res) => [...res.integrations].sort((a, b) => (semver.gt(a.version, b.version) ? -1 : 1))),
  'workspaces_/$workspaceId_/promoCode': async ({
    workspaceId,
  }: {
    workspaceId: string
  }): Promise<PromoCode | null> => {
    const resp = await BillingApiClient.getPromoCode(workspaceId)
    if (_.isEmpty(resp)) {
      return null
    }
    return resp as PromoCode
  },
  'workspaces_/$workspaceId_/promoCodeSearch/$query': async ({
    workspaceId,
    query,
  }: {
    workspaceId: string
    query: string
  }): Promise<PromoCode | null> => {
    const resp = await BillingApiClient.searchPromoCode({ workspaceId, query })
    if (_.isEmpty(resp)) {
      return null
    }
    return resp as PromoCode
  },
  'fetch/$url': async ({ url }: { url: string }) => {
    const file = await axios.get(url, {
      withCredentials: false,
    })

    return file?.data ?? ''
  },
  'workspaces_/$workspaceId_/bots_/$botId_/tables/$table_': async ({
    workspaceId,
    botId,
    table,
  }: {
    workspaceId: string
    botId: string
    table: string
  }) => getApiClientForBot({ workspaceId, botId }).getTable({ table: table }),
  'workspaces_/$workspaceId_/bots_/$botId_/tables/$tags/all': async ({
    workspaceId,
    botId,
    tags,
  }: {
    workspaceId: string
    botId: string
    tags: Record<string, string>
  }) => getApiClientForBot({ workspaceId, botId }).listTables({ tags }),
  'workspaces_/$workspaceId_/bots_/$botId_/tables/$table_/rows/$filter/$limit/$group/$offset/$orderBy/$orderDirection/$search':
    async ({
      workspaceId,
      botId,
      ...rest
    }: {
      workspaceId: string
      botId: string
      table: string
      filter?: Record<string, any>
      limit?: number
      group?: Record<string, any>
      offset?: number
      orderBy?: string
      orderDirection?: 'asc' | 'desc'
      search?: string
    }) => {
      const client = getApiClientForBot({ workspaceId, botId })
      return client.findTableRows(rest)
    },
  'workspaces_/$workspaceId_/usage/activities/$type/daily/$startDate/$endDate': async ({
    workspaceId,
    type,
    startDate,
    endDate,
  }: {
    workspaceId: string
    type: QuotaType
    startDate?: string
    endDate?: string
  }) => {
    return getApiClient(workspaceId)
      .list.usageActivityDaily({
        id: workspaceId,
        type,
        dateFrom: startDate,
        dateUntil: endDate,
      })
      .collect()
  },
  'workspaces_/$workspaceId_/usage/activities/$type/$startDate/$endDate': async ({
    workspaceId,
    type,
    startDate,
    endDate,
  }: {
    workspaceId: string
    type: QuotaType
    startDate?: string
    endDate?: string
  }) => {
    return getApiClient(workspaceId)
      .list.usageActivity({
        id: workspaceId,
        type,
        timestampFrom: startDate,
        timestampUntil: endDate,
      })
      .collect()
  },
} as const satisfies Record<FlattenedKeys, (props: any) => Promise<any> | any>

export const getQueryKey = <T extends FlattenedKeys>(
  path: T,
  ...param: [PathParams<T>] extends [never] ? [] : [PathParams<T>]
) => {
  const [paramsOjb] = param

  return path.split('/').map((key) => {
    return key.startsWith('$') ? (paramsOjb as Record<string, any>)[key.replace(/^\$|_$/g, '')] : key
  })
}

type QueryFunctionParams<T extends FlattenedKeys> = Parameters<(typeof queryFunctions)[T]>[0]

type ParamType<T extends FlattenedKeys> = [PathParams<T>] extends [never]
  ? QueryFunctionParams<T> extends undefined
    ? []
    : [QueryFunctionParams<T>]
  : QueryFunctionParams<T> extends undefined
    ? [PathParams<T>]
    : [QueryFunctionParams<T>]

export const getQueryOptions = <T extends FlattenedKeys>(path: T, ...param: ParamType<T>) => {
  const [paramsOjb] = param

  const queryKey = path.split('/').map((key) => {
    return key.startsWith('$') ? (paramsOjb as Record<string, any>)[key.replace(/^\$|_$/g, '')] : key
  })

  const queryFunction = queryFunctions[path]

  return queryOptions({
    queryKey,
    enabled:
      paramsOjb &&
      Object.values(paramsOjb as Record<string, unknown>).every((value) => value !== undefined && value !== ''),
    queryFn: (): Awaited<ReturnType<typeof queryFunction>> => queryFunction(paramsOjb as any) as any,
    staleTime: cacheDuration.short,
    meta: {
      suppressError: true,
    },
  })
}

/**
 * This is a custom hook that is used to fetch data from the server using the functions defined in the queryFunctions object.
 *  It is a wrapper around the useQuery hook from react-query.
 */
export const useQuery = <T extends FlattenedKeys>(...props: Parameters<typeof getQueryOptions<T>>) =>
  useTanstackQuery(getQueryOptions(...props))

/**
 * This is a custom hook that is used to fetch data from the server using the functions defined in the queryFunctions object.
 *  It is a wrapper around the useQuery hook from react-query.
 */
export const useSuspenseQuery = <T extends FlattenedKeys>(...props: Parameters<typeof getQueryOptions<T>>) =>
  useTanstackSuspenseQuery(getQueryOptions(...props))
