import { Flex, Grid, Separator, Text } from '@radix-ui/themes'
import { useQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import {
  botQueryOptions,
  conversationQueryOptions,
  getIntegrationByNameQueryOptions,
  getStateQueryOptions,
  listConversationsInfiniteQuery,
  messageQueryOptions,
} from '~/queries'
import { EmptyState, Icon, Link, Spinner } from '~/elementsv2'
import { Boundary, Identifier, QueryParamBuilder, type FilterMenuInput, Page } from '~/componentsV2'
import { useIntegrations } from '~/hooks/integrations/useIntegrations'
import { Fragment } from 'react'
import { z } from 'zod'
import type { Conversation } from '@botpress/client'
import { DateTime } from 'luxon'
import EmptyStateIcon from '~/assets/programming-04.svg?react'
import { useInfiniteScroll } from '~/hooks'
import { Button, Card } from '@bpinternal/ui-kit'
import { FILTER_SEPARATOR } from '~/componentsV2/QueryParamBuilder/parseParams'

const conversationsSearchSchema = z.object({
  conversationId: z
    .string()
    .optional()
    .catch(() => undefined),
  messageId: z
    .string()
    .optional()
    .catch(() => undefined),
  integrationName: z
    .string()
    .optional()
    .catch(() => undefined),
  participantIds: z
    .array(z.string())
    .optional()
    .catch(() => undefined),
  tags: z
    .record(z.string())
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/conversations/')({
  validateSearch: conversationsSearchSchema,
  component: Component,
})

function Component() {
  return (
    <Boundary height={'200px'} loaderSize="6">
      <ConversationsPage />
    </Boundary>
  )
}

const ConversationsPage = () => {
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data
  const botIntegrations = useIntegrations({
    workspaceId,
    integrationIds: Object.values(bot.integrations).map((i) => i.id),
  })

  const integrationTags = useIntegrations({
    workspaceId,
    integrationIds: Object.values(bot.integrations).map((i) => i.id),
  })
    .filter((i) => Object.values(i.channels).flatMap((channel) => Object.keys(channel.conversation.tags)).length > 0)
    .map<FilterMenuInput>((i) => ({
      name: i.name,
      icon: <Icon size="2" variant={'surface'} color={'gray'} icon={(props) => <img src={i.iconUrl} {...props} />} />,
      value: Object.values(i.channels).flatMap((channel) =>
        Object.keys(channel.conversation.tags).map<FilterMenuInput>((t) => ({
          name: t,
          paramName: `tags`,
          type: 'object',
          value: `${i.name}:${t}${FILTER_SEPARATOR}`,
        }))
      ),
    }))

  const filters: FilterMenuInput[] = [
    { name: 'Conversation', paramName: 'conversationId', type: 'string' },
    { name: 'Message', paramName: 'messageId', type: 'string' },
    { name: 'Participant', paramName: 'participantIds', type: 'array' },
    {
      name: 'Integration',
      value: botIntegrations.map((integration) => ({
        name: integration.name,
        paramName: 'integrationName',
        value: integration.name,
        icon: (
          <Icon
            size="2"
            variant={'surface'}
            color={'gray'}
            icon={(props) => <img src={integration.iconUrl} {...props} />}
          />
        ),
      })),
    },
    ...Object.keys(bot.conversation.tags)
      .filter((t) => !['upstream', 'downstream'].includes(t))
      .map<FilterMenuInput>((t) => ({
        name: t,
        paramName: 'tags',
        type: 'object',
        value: `${t}${FILTER_SEPARATOR}`,
      })),
    { name: 'Integration Tags', value: integrationTags },
  ]

  return (
    <Page title="Conversations">
      <Flex direction={'column'} gap={'4'}>
        <QueryParamBuilder filters={filters} />
        <Boundary loaderSize={'6'}>
          <ConversationList />
        </Boundary>
      </Flex>
    </Page>
  )
}

const ConversationList = () => {
  const { workspaceId, botId } = Route.useParams()
  const { conversationId, messageId, integrationName, tags, participantIds } = Route.useSearch()

  const {
    data: fetchedConversations,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useSuspenseInfiniteQuery(
    listConversationsInfiniteQuery({ botId, workspaceId, integrationName, tags, participantIds })
  )
  const bottomRef = useInfiniteScroll(fetchNextPage, hasNextPage)

  const { data: fetchedMessage } = useQuery({
    ...messageQueryOptions({ messageId: messageId ?? '', botId, workspaceId }),
    enabled: !!messageId,
  })

  const { data: fetchedConversation } = useQuery({
    ...conversationQueryOptions({
      botId,
      workspaceId,
      conversationId: conversationId ?? fetchedMessage?.message.conversationId ?? '',
    }),
    enabled: !!conversationId || !!fetchedMessage?.message.conversationId,
  })

  const conversations = fetchedConversation?.conversation
    ? [fetchedConversation.conversation]
    : (fetchedConversations.pages?.flatMap((page) => page.conversations) ?? [])

  return (
    <>
      <Card className="p-0">
        <Text asChild size={'2'}>
          <Grid gapX={'7'} className="relative grid-cols-[repeat(4,auto),1fr]">
            <Grid align={'center'} p={'2'} className="col-[1/-1] grid-cols-subgrid bg-gray-2 font-medium">
              <div />
              <Text>Integration</Text>
              <Text>Last Modified</Text>
              <Text>Conversation Identifier</Text>
              <Text>Summary</Text>
            </Grid>
            {conversations.length === 0 ? (
              <>
                <Separator size={'4'} className="col-[1/-1]" />
                <EmptyState
                  iconSize={6}
                  icon={EmptyStateIcon}
                  className="col-[1/-1] py-16"
                  title="You don't have any conversations!"
                  description="Conversations will appear here once you start interacting with your bot."
                  primaryAction={
                    <Link to="/workspaces/$workspaceId/bots/$botId/webchat" params={{ workspaceId, botId }}>
                      <Button>Start chatting!</Button>
                    </Link>
                  }
                />
              </>
            ) : (
              conversations.map((conversation, i) => (
                <Fragment key={i}>
                  <Separator size={'4'} className="col-[1/-1]" />
                  <ConversationRow conversation={conversation} />
                </Fragment>
              ))
            )}
          </Grid>
        </Text>
      </Card>
      <div ref={bottomRef} />
      {isFetching && (
        <Flex className="w-full items-center justify-center">
          <Spinner size="6" />
        </Flex>
      )}
    </>
  )
}

const ConversationRow = ({ conversation }: { conversation: Conversation }) => {
  const { workspaceId, botId } = Route.useParams()
  const { id, integration, updatedAt } = conversation
  const { data: fetchedIntegration } = useQuery(getIntegrationByNameQueryOptions({ name: integration, workspaceId }))
  const { data: fetchedState } = useQuery(
    getStateQueryOptions({
      botId,
      workspaceId,
      id,
      type: 'conversation',
      name: 'agentsConversationVariables',
    })
  )

  return (
    <Grid p={'2'} align={'center'} className="col-[1/-1] grid-cols-subgrid">
      <Icon
        size="3"
        className="-mr-8"
        variant={'surface'}
        color={'gray'}
        icon={(props) => <img src={fetchedIntegration?.iconUrl} {...props} />}
      />
      <Link
        to="/workspaces/$workspaceId/bots/$botId/conversations/$conversationId"
        params={{ workspaceId, botId, conversationId: id }}
      >
        {fetchedIntegration?.title}
      </Link>
      <Text color="gray">{DateTime.fromISO(updatedAt).toRelative()}</Text>
      <Identifier id={id} logsStartDate={DateTime.fromISO(updatedAt).minus({ days: 1 }).toISODate()} />
      <Text highContrast size={'1'} className="line-clamp-3">
        {fetchedState?.state?.payload.SummaryAgent?.summary}
      </Text>
    </Grid>
  )
}
