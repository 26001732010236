import { z } from 'zod'
import { getGenericClient } from '../client'
import { isApiError } from '@botpress/client'
import { analyticsBoardConfigSchema } from '../features/analytics'
import { defaultChartLayout } from '../features/analytics/constants'

type SplitPath<P extends string> = P extends `${infer X}/${infer Y}` ? X | SplitPath<Y> : P
type KeepPathVar<P extends string> = P extends `$${infer Var}` ? Var : never
type AsRecord<T extends string> = [T] extends [never] ? never : { [K in T]: string }
type PathParams<P extends string> = AsRecord<KeepPathVar<SplitPath<P>>>

type PreferenceConfig<Schema = any, Default extends Schema = Schema, Error extends Schema = Schema> = {
  schema: z.ZodType<Schema>
  default: Default // TODO the default value doesn't work with the schema type yet so it's not enforced in the types
  error?: Error // TODO the error value doesn't work with the schema type yet so it's not enforced in the types
}

export const WebchatCurrentRevision = 1

/*
 * Warning! This is the source of truth for preference config.
 * Do not update the preference with any breaking changes otherwise it will break the app for users with the old preferences.
 * If you need to update the preference, create a new preference and migrate the old preference to the new one.
 */
export const preferenceConfig = {
  requireOnboarding: {
    schema: z.boolean().catch(() => false),
    default: false,
    error: false,
  },
  hasSeenNewDashboardDialog: {
    schema: z.boolean().catch(() => false),
    default: false,
    error: true,
  },
  '$workspaceId/botHistory': {
    schema: z.array(z.string()).catch(() => []),
    default: [],
    error: [],
  },
  '$workspaceId/$botId/analyticsGridBoard': {
    schema: analyticsBoardConfigSchema,
    default: defaultChartLayout,
  },
  '$workspaceId/$botId/webchatV2Revision': {
    schema: z.number().catch(() => 0),
    default: 0,
    error: WebchatCurrentRevision,
  },
  '$tableId/columnWidths': {
    schema: z
      .record(z.number())
      .optional()
      .catch(() => ({})),
    default: {},
    error: {},
  },
} as const satisfies { [key: string]: PreferenceConfig }

type PreferencesConfig = typeof preferenceConfig
export type Preferences = { [K in keyof PreferencesConfig]: z.infer<PreferencesConfig[K]['schema']> }

export type GetPreferenceProps<T extends keyof Preferences> = [PathParams<T>] extends [never]
  ? { path: T }
  : { path: T; params: PathParams<T> }
export type SetPreferenceProps<T extends keyof Preferences> = GetPreferenceProps<T> & { value: Preferences[T] }

const client = getGenericClient()

export const getPreference = async <T extends keyof Preferences>(
  props: GetPreferenceProps<T>
): Promise<Preferences[T]> => {
  const pref = await client.getAccountPreference({ key: resolveKey(props) }).catch((err) => {
    if (isApiError(err) && err.type === 'ResourceNotFound') {
      return { value: preferenceConfig[props.path].default }
    }
    throw err
  })

  return pref.value
}

export type SetPreferencePropsUnion = { [K in keyof Preferences]: SetPreferenceProps<K> }[keyof Preferences]

export const setPreference = async (props: SetPreferencePropsUnion) => {
  await client.setAccountPreference({ key: 'params' in props ? resolveKey(props) : props.path, value: props.value })
}

export const resolveKey = (props: { path: string; params?: Record<string, string> }) => {
  const params = 'params' in props ? (props.params ?? {}) : {}
  return Object.keys(params).reduce((acc, key) => acc.replace(`$${key}`, (params as any)[key]), props.path)
}
