import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'
import { GetPreferenceProps, Preferences, resolveKey } from '../services/preferences'
import { cacheDuration } from '../shared'
import { getQueryKey, queryFunctions } from '~/services'
import { ClientInputs } from '@botpress/client'
import { useQueryKeys } from '~/hooks'
import { getApiClientForBot } from '~/client'

export const userPreferencesQueryOptions = <T extends keyof Preferences>(props: GetPreferenceProps<T>) => {
  return queryOptions({
    queryKey: getQueryKey('preferences/$path', { path: resolveKey(props) }),
    queryFn: async () => queryFunctions['preferences/$path'](props),
    staleTime: cacheDuration.medium,
  })
}

export type ListUsersProps = { botId: string; workspaceId: string } & Pick<
  ClientInputs['listUsers'],
  'conversationId' | 'tags'
>

export const listUsersInfiniteQuery = ({ botId, workspaceId, ...props }: ListUsersProps) => {
  const { listUsers } = useQueryKeys()
  const client = getApiClientForBot({ workspaceId, botId })

  return infiniteQueryOptions({
    staleTime: cacheDuration.short,
    queryKey: listUsers({ botId, workspaceId, ...props }),
    queryFn: async ({ pageParam }) =>
      client
        .listUsers({
          nextToken: pageParam,
          ...props,
        })
        .catch((e) => {
          console.error('Failed to fetch Users', e)
          return { users: [], meta: { nextToken: null } }
        }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
    meta: {
      suppressError: true,
    },
  })
}
