import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Progress,
} from '@bpinternal/ui-kit-next'
import type { ReactNode } from 'react'
import { cn } from '~/utils'

type Props = {
  className?: string
  title?: ReactNode
  description?: ReactNode
  footer?: ReactNode
  value?: number
  children?: ReactNode
}

export const ProgressBarCard = ({ className, description, footer, value, title, children, ...props }: Props) => {
  return (
    <Card {...props} className={cn(className, 'flex flex-col')}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className="mt-auto">
        {children}
        <Progress value={value} />
      </CardContent>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  )
}
