import { Flex, HoverCard, Link as RadixLink, Text } from '@radix-ui/themes'
import { Link } from '~/elementsv2'
import { ClientOutputs, Workspace } from '@botpress/client'
import { IntegrationIcon } from '~/features/integrations/components'
import { ActiveWorkspace } from '~/stores'
import { useCurrentRouteId } from '~/hooks'
import { useSuspenseQuery } from '~/services'

type EmptyStateProps = {
  workspace: ActiveWorkspace
  userIsMember: boolean
}

const EmtpyState = ({ workspace, userIsMember }: EmptyStateProps) => (
  <Text size={'1'} className="text-gray-11">
    {workspace.name} has no contributions.
    {userIsMember && (
      <>
        <br />
        <br />
        Start contributing to Botpress by&nbsp;
        <RadixLink
          href="https://botpress.com/docs/developers/howTo/build-integration"
          className="!text-accent-11"
          target="_blank"
        >
          building an integration
        </RadixLink>
        &nbsp;and share it with the community.
      </>
    )}
  </Text>
)

type IntegrationList = ClientOutputs['listPublicIntegrations']['integrations']
const IntegrationsItems = ({ integrations }: { integrations: IntegrationList }) => (
  <Flex gap={'2'} wrap={'wrap'}>
    {integrations?.map((integration) => (
      <HoverCard.Root key={integration.id}>
        <HoverCard.Trigger>
          <Link to="/hub/integrations/$integrationId" params={{ integrationId: integration.id }}>
            <IntegrationIcon
              className="h-8 w-8 cursor-pointer rounded border border-gray-4 bg-gray-3 p-1.5 saturate-[75%]"
              iconUrl={integration.iconUrl}
            />
          </Link>
        </HoverCard.Trigger>
        <HoverCard.Content side="top" className="max-w-96">
          <Flex direction={'column'} gap={'2'}>
            <Flex align={'center'} gap={'2'}>
              <IntegrationIcon className="h-4 w-4 saturate-[75%]" iconUrl={integration.iconUrl} />
              <Text size={'2'}>{integration.title}</Text>
            </Flex>
            {integration.description && (
              <Text color="gray" size={'1'}>
                {integration.description}
              </Text>
            )}
          </Flex>
        </HoverCard.Content>
      </HoverCard.Root>
    ))}
  </Flex>
)

type ContributionsProps = {
  workspace: ActiveWorkspace
  userWorkspaces: Workspace[]
}

//TODO: show more in a collapsible when list is longer than 12
// this will most likely happen when we introduce community workflows and agents
export const ContributionsSidebarSection = ({ workspace, userWorkspaces }: ContributionsProps) => {
  const MAX_DISPLAY = 12
  const currentRoute = useCurrentRouteId()
  const userIsMember = userWorkspaces.some((w) => w.id === workspace.id)

  const workspacePublicIntegrations = useSuspenseQuery('integrations_')
    .data.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
    .filter((i) => i.ownerWorkspace.id === workspace.id)

  const shouldshowMore =
    userIsMember && currentRoute === '/workspaces/$workspaceId/home' && workspacePublicIntegrations.length > MAX_DISPLAY

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight="medium">
        Hub Contributions
      </Text>
      {workspacePublicIntegrations.length > 0 ? (
        <>
          <IntegrationsItems integrations={workspacePublicIntegrations.slice(0, MAX_DISPLAY)} />
          {shouldshowMore && (
            <Link size={'1'} to="/workspaces/$workspaceId/integrations" params={{ workspaceId: workspace.id }}>
              View all
            </Link>
          )}
        </>
      ) : (
        <EmtpyState workspace={workspace} userIsMember={userIsMember} />
      )}
    </Flex>
  )
}
