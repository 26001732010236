import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { User } from '../features/users/types'
import { DEFAULT_LOCALE } from '../shared'
import { Workspace, ClientOutputs } from '@botpress/client'

export type ActiveWorkspace = Workspace | (ClientOutputs['getPublicWorkspace'] & { plan?: Workspace['plan'] })

type AppState = {
  locale: string
  currentUser?: User
  activeWorkspace?: ActiveWorkspace
  setActiveWorkspace: (workspace: ActiveWorkspace) => void
  setCurrentUser: (user?: User) => void
}

export const useAppStore = create<AppState>()(
  devtools((set) => ({
    locale: DEFAULT_LOCALE,
    setActiveWorkspace: (activeWorkspace) => set({ activeWorkspace }),
    setCurrentUser: (user?: User) => set({ currentUser: user }),
  }))
)
