import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useQueryKeys } from '..'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { getQueryKey } from '~/services'
import { getApiClient } from '~/client'
import { isApiError } from '@botpress/client'

type Props = {
  integrationId: string
  workspaceId: string
  onSuccess?: () => void
}

export const useSetIntegrationUrl = ({ workspaceId, integrationId, onSuccess }: Props) => {
  const queryClient = useQueryClient()
  const { listWorkspaceIntegrations, getIntegrationByName, getIntegration } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ url }: { url: string | null }) => {
      return getApiClient(workspaceId).updateIntegration({
        id: integrationId,
        url,
      })
    },
    onError: (err) => {
      if (isApiError(err)) {
        showErrorToast(err.message)
      } else {
        showErrorToast('Failed to update integration')
      }
    },
    onSuccess: async ({ integration }) => {
      const queryKeys = [
        getQueryKey('integrations_'),
        listWorkspaceIntegrations(workspaceId),
        getIntegration(integration.id),
        getIntegrationByName(integration.name, integration.version),
      ]
      // we have to do this because the way we structured the integration query keys won't invalidate properly if I use [INTEGRATION]
      await Promise.all(queryKeys.map((queryKey) => queryClient.invalidateQueries({ queryKey })))
      showSuccessToast('Integration updated successfully')
      onSuccess?.()
    },
  })
}
