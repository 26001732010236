import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import { useDetailedUsage, useUsage } from '~/hooks'
import { QuotaType } from '@bpinternal/const'
import { useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { listBotsQueryOptions } from '~/queries'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '~/features/usage/constants'
import { BreakdownByBotCard, CumulativeAiSpend, ProgressBarCard } from '~/features/usage/components'
import { PageActionPortal, UsageMonthSelector } from '~/componentsV2'
import { useSuspenseQuery } from '~/services'
import { mergeDailyUsages } from '~/features/usage/utils'
import { formatValueWithUnit } from '~/features/usage/helpers'

export const Route = createFileRoute('/workspaces/$workspaceId/usage/monthly-summary')({
  component: RouteComponent,
})

function RouteComponent() {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())

  const { workspaceId } = Route.useParams()

  const bots = useTanstackSuspenseQuery(listBotsQueryOptions(workspaceId)).data

  const { ai_spend, invocation_calls, ...storageUsages } = useDetailedUsage({
    workspaceId,
    quotas: ['ai_spend', 'storage_count', 'table_row_count', 'knowledgebase_vector_storage', 'invocation_calls'],
    period: selectedMonth.toISOString().split('T')[0] ?? '',
  })

  const messagesUsages = { ai_spend, invocation_calls }

  const basicUsage = useUsage({
    workspaceId,
    quotas: ['workspace_member_count', 'bot_count', 'always_alive'],
    period: selectedMonth.toISOString().split('T')[0] ?? '',
  })

  const { data: dailyOpenAiUsage } = useSuspenseQuery(
    'workspaces_/$workspaceId_/usage/activities/$type/daily/$startDate/$endDate',
    {
      type: 'openai_spend',
      workspaceId,
      startDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).toISOString(),
      endDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).toISOString(),
    }
  )

  const { data: dailyBingUsage } = useSuspenseQuery(
    'workspaces_/$workspaceId_/usage/activities/$type/daily/$startDate/$endDate',
    {
      type: 'bing_search_spend',
      workspaceId,
      startDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).toISOString(),
      endDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).toISOString(),
    }
  )

  const dailyAiSpendUsage = mergeDailyUsages(dailyOpenAiUsage, dailyBingUsage)

  const getBotProperties = (botId: string) => bots.find((b) => b.id === botId) ?? { name: '[Deleted bot]' }

  return (
    <>
      <PageActionPortal>
        <UsageMonthSelector
          selectedMonth={selectedMonth}
          debouncedOnChange={(date) => setSelectedMonth(date)}
          className="ml-auto"
        />
      </PageActionPortal>
      <div className="flex flex-col gap-4 @container">
        <CumulativeAiSpend data={dailyAiSpendUsage} selectedMonth={selectedMonth} />
        <div className="grid grid-cols-1 gap-4 @3xl:grid-cols-3">
          {Object.values(basicUsage).map(({ quota, value, type, percentage }) => {
            return (
              <ProgressBarCard
                key={type}
                title={QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].name}
                description={QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].description}
                value={percentage}
              >
                <div className="flex justify-between pb-1 text-sm text-gray-11">
                  <span>
                    {formatValueWithUnit(value, type)} of {formatValueWithUnit(quota, type)}
                  </span>
                  <span>{percentage.toFixed(0)}%</span>
                </div>
              </ProgressBarCard>
            )
          })}
        </div>
        <div className="grid grid-cols-1 grid-rows-[auto,1fr] gap-4 @4xl:grid-cols-2 @4xl:gap-y-0">
          {Object.entries(messagesUsages).map(([type, usage]) => (
            <BreakdownByBotCard
              size="lg"
              key={type}
              usage={{
                ...usage,
                breakdown: usage.breakdown
                  .filter((d) => d.value > 0)
                  .map(({ botId, value }) => ({
                    botId,
                    value,
                    ...getBotProperties(botId),
                  })),
              }}
              type={type as QuotaType}
            />
          ))}
        </div>
        <div className="grid grid-cols-1 grid-rows-[auto,1fr] gap-4 @4xl:grid-cols-3 @4xl:gap-y-0">
          {Object.entries(storageUsages).map(([type, usage]) => (
            <BreakdownByBotCard
              key={type}
              size="sm"
              usage={{
                ...usage,
                breakdown: usage.breakdown
                  .filter((d) => d.value > 0)
                  .map(({ botId, value }) => ({
                    botId,
                    value,
                    ...getBotProperties(botId),
                  })),
              }}
              type={type as QuotaType}
            />
          ))}
        </div>
      </div>
    </>
  )
}
