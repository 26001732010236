'use client'

import { ChevronsUpDown } from 'lucide-react'

import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from '@bpinternal/ui-kit-next'
import type { Workspace } from '@botpress/client'
import { useAppStore } from '../../stores'

import { Avatar } from '../../elementsv2'

import { User } from '~/features/users/types'
import { UserDropdown } from './UserDropdown'

export function NavUser({ workspaces }: { workspaces: Workspace[] }) {
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const user = useAppStore((s) => s.currentUser)

  const userHasAccessToWorkspace = workspaces.some((w) => w.id === activeWorkspace?.id)

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        {/* Can only cast user here is dropdown is disabled when undefined */}
        <UserDropdown disabled={!user} user={user as User} asChild hasAccessToWorkspace={userHasAccessToWorkspace}>
          <SidebarMenuButton
            size="lg"
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
          >
            <Avatar className="size-8" name={user?.email} pictureUrl={user?.profilePicture} />
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-semibold">{user?.displayName}</span>
              <span className="truncate text-xs">{user?.email}</span>
            </div>
            <ChevronsUpDown className="ml-auto size-4" />
          </SidebarMenuButton>
        </UserDropdown>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
