import { Flex, Text, Link as ThemeLink } from '@radix-ui/themes'
import { WorkspaceProfilePicture } from './WorkspaceProfilePicture'
import { HiOutlineUser } from 'react-icons/hi2'
import { Link, SocialLink } from '~/elementsv2'
import { ClientOutputs } from '@botpress/client'

const WorkspaceSocialHeader = ({ workspace }: { workspace: ClientOutputs['getPublicWorkspace'] }) => {
  const shouldShowPlaceholder =
    workspace.socialAccounts?.length === 0 &&
    !workspace.contactEmail &&
    !workspace.website &&
    !workspace.handle &&
    !workspace.about

  return (
    <Flex mx={'auto'} align={'center'} gap={'5'} className="mb-2.5">
      <WorkspaceProfilePicture workspaceId={workspace.id} pictureUrl={workspace.profilePicture} className="h-36 w-36" />
      <Flex flexGrow={'1'} gap={'1'} direction={'column'}>
        <Text size={'6'} weight={'bold'}>
          {workspace.name}
        </Text>
        {shouldShowPlaceholder && (
          <Text size={'1'} weight={'medium'} className="italic" color="gray">
            Workspace profile not set. To edit go to{' '}
            <Link
              color="gray"
              underline="always"
              to="/workspaces/$workspaceId/settings/details"
              params={{ workspaceId: workspace.id }}
            >
              settings
            </Link>
          </Text>
        )}
        {workspace.handle && (
          <Flex align={'center'}>
            <Text size={'3'} weight={'medium'} className="test-gray-10 italic">
              {workspace.handle}
            </Text>
          </Flex>
        )}
        <Flex gap="3" wrap={'wrap'} align={'end'}>
          {workspace.contactEmail && (
            <ThemeLink href={`mailto:${workspace.contactEmail}`} className="text-gray-11">
              <Flex align={'center'}>
                <HiOutlineUser className="mr-1" />
                <Text size={'2'}>{workspace.contactEmail}</Text>
              </Flex>
            </ThemeLink>
          )}
          <SocialLink text={workspace.website} />
          {(workspace.socialAccounts ?? []).map((account) => (
            <SocialLink key={account} text={account} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export { WorkspaceSocialHeader }
