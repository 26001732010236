import { QuotaType } from '@bpinternal/const'

export const BOT_LEVEL_QUOTA_CONFIGS: QuotaType[] = [
  'invocation_calls',
  'storage_count',
  'table_row_count',
  'knowledgebase_vector_storage',
  'ai_spend',
]

/**
 * These quotas are the ones that can blow up at runtime, so we want to show them to the user.
 */
export const DYNAMIC_QUOTAS: QuotaType[] = [
  'ai_spend',
  'invocation_calls',
  'table_row_count',
  'knowledgebase_vector_storage',
  'storage_count',
]

export const QuotaUsedPercentageWarningThreshold = 75

export const QUOTA_TYPE_USER_FACING_PROPERTIES_MAP: Record<QuotaType, { name: string; description: string }> = {
  bing_search_spend: { name: '', description: '' },
  openai_spend: { name: '', description: '' },
  bot_count: {
    name: 'Bot Count',
    description: 'The number of bots in your workspace',
  },
  invocation_timeout: {
    name: 'Invocation Timeout',
    description: 'The maximum amount of time your bots can run for',
  },
  workspace_member_count: {
    name: 'Collaborators',
    description: 'The number of members in your workspace',
  },
  table_row_count: {
    name: 'Table Rows',
    description: 'The number of rows in your tables',
  },
  storage_count: {
    description: 'This is the storage for files that are not vectorized (e.g. images used in conversations).',
    name: 'File Storage',
  },
  integrations_owned_count: {
    description: 'The number of integrations owned by your workspace',
    name: 'Integrations',
  },
  knowledgebase_vector_storage: {
    description: 'This is the storage for Knowledge Base documents. 100MB is about 28,500 vectors',
    name: 'Vector DB Storage',
  },
  invocation_calls: {
    description: 'Incoming messages and events that trigger bot activation.',
    name: 'Incoming Messages & Events',
  },
  ai_spend: {
    description:
      'Track your usage of third-party AI services here. This meter helps you monitor your spend against your budget.',
    name: 'AI Spend',
  },
  workspace_ratelimit: {
    description: 'The number of requests per minute your workspace can make',
    name: 'Rate Limit',
  },
  always_alive: {
    description: 'The number of bots that are in always alive mode',
    name: 'Always Alive',
  },
}
