import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient } from '~/client'
import { BotSummary } from '~/features/bots/types'
import { WorkspaceObject, WorkspaceSummary } from '~/features/workspaces/types'
import { getCdmStudioUrl, handleHttpErrors } from '~/shared'
import { getQueryKey } from '~/services'
import { trackEvent } from '~/providers'
import { updateBot } from '~/features/bots/services'
import { defaultWebchatTheme } from '~/features/webchat/configs'
import { showConfirmationPrompt, showErrorToast } from '@bpinternal/ui-kit'
import { isApiError } from '@botpress/client'
import { useNavigate } from '@tanstack/react-router'

export function useCreateBot(workspaceId: string) {
  const queryClient = useQueryClient()
  const listBotsQueryKey = getQueryKey('workspaces_/$workspaceId_/bots_', { workspaceId })
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (name?: string) => {
      return await getApiClient(workspaceId).createBot({ name }).catch(handleHttpErrors)
    },
    onSuccess: async (data) => {
      trackEvent({ type: 'create_bot', botId: data.bot.id })

      const { integration: webchatIntegration } = await getApiClient(workspaceId)
        .getPublicIntegration({ name: 'webchat', version: 'latest' })
        .catch(handleHttpErrors)
      if (webchatIntegration) {
        await updateBot({
          workspaceId,
          id: data.bot.id,
          integrations: {
            [webchatIntegration.id]: { enabled: true, configuration: defaultWebchatTheme },
          },
        }).catch(handleHttpErrors)
      }
      queryClient.setQueryData<BotSummary[]>(listBotsQueryKey, (bots) => {
        const prevBots = bots || []
        const { id, name, createdAt, updatedAt, deployedAt, tags } = data.bot
        return [...prevBots, { id, name, createdAt, updatedAt, deployedAt, tags }]
      })
      queryClient.setQueryData<WorkspaceSummary[]>(getQueryKey('workspaces_'), (workspaces) => {
        return workspaces?.map((workspace) => {
          if (workspace.id === workspaceId) {
            return {
              ...workspace,
              botCount: (workspace.botCount || 0) + 1,
            }
          }
          return workspace
        })
      })

      const workspaceQueryKey = getQueryKey('workspaces_/$workspaceId_', { workspaceId })
      queryClient.setQueryData<WorkspaceObject>(workspaceQueryKey, (workspace) => {
        if (!workspace) {
          return workspace
        }
        return {
          ...workspace,
          botCount: workspace.botCount + 1,
        }
      })

      showConfirmationPrompt('Your chatbot has been created successfully', {
        title: 'Chatbot created',
        confirmLabel: 'Open in Studio',
        cancelLabel: 'Close',
      }).then(() => {
        trackEvent({ type: 'open_bot_after_creation', botId: data.bot.id })
        window.open(getCdmStudioUrl(data.bot.id))
      })
    },
    onError: (error) => {
      if (isApiError(error) && error.type === 'QuotaExceeded') {
        showConfirmationPrompt(
          'You have reached the limit of bots you can create. Please upgrade your plan to create more bots.',
          {
            title: 'Limit Exceeded',
            confirmLabel: 'Upgrade',
            cancelLabel: 'Close',
          }
        ).then(() => {
          navigate({ to: '/workspaces/$workspaceId/billing/add-ons', from: '/workspaces/$workspaceId' })
        })
      } else {
        showErrorToast(`An error occurred while creating the chatbot: ${error.message}`)
      }
    },
    meta: {
      suppressError: true,
    },
  })
}
