import { Bot } from '@botpress/client'

export function getIntegrationsFromBot(bot?: Bot) {
  return Object.values(bot?.integrations ?? {}).map((integration) => ({
    ...integration,
  }))
}

/**
 * Makes sure that data has valid keys according to the schema
 */
export function validateConfigurationData(configuration: Bot['configuration']): Bot['configuration']['data'] {
  const validatedData: Record<string, any> = {}
  const data = configuration.data
  Object.keys(configuration.schema?.properties ?? {}).forEach((propertyKey) => {
    validatedData[propertyKey] = data[propertyKey]
  })

  return validatedData
}
