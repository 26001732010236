import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@bpinternal/ui-kit-next'
import { useState, type FC } from 'react'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '../constants'
import { formatters } from '../../../utils'
import { addDailyAverage, fillMissingDates } from '../utils'

const chartConfig = {
  value: {
    label: 'Daily AI Spend',
    color: 'hsl(var(--chart-2))',
  },
  cumulative: {
    label: 'AI Spend',
    color: 'hsl(var(--chart-1))',
  },
  dailyAverage: {
    label: 'Average AI Spend',
    color: 'hsl(var(--border))',
  },
  projectedCumulative: {
    label: 'Projected AI Spend',
    color: 'hsl(var(--border))',
  },
} satisfies ChartConfig

type SpendType = 'total' | 'daily'
type Props = {
  data?: { date: string; value: number }[]
  selectedMonth: Date
}

const generateMonthTicks = (selectedMonth: Date) => {
  const year = selectedMonth.getFullYear()
  const month = selectedMonth.getMonth()
  const daysInMonth = new Date(year, month + 1, 0).getDate()

  const ticks = []
  for (let day = 1; day <= daysInMonth; day++) {
    ticks.push(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`)
  }

  return ticks
}

export const CumulativeAiSpend: FC<Props> = ({ data, selectedMonth }) => {
  const [activeChart, setActiveChart] = useState<SpendType>('total')
  const totalAiSpend = data?.reduce((acc, { value }) => acc + value, 0) ?? 0

  const sortedData = fillMissingDates(
    [...(data ?? [])].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
    selectedMonth
  )
  const averageAiSpendPerDay = totalAiSpend ? totalAiSpend / (sortedData?.length ?? 1) : 0

  const dataWithProjection = addDailyAverage(sortedData, selectedMonth, averageAiSpendPerDay)

  const dataWithCumulativeValue = dataWithProjection.map((item, index) => ({
    ...item,
    ...(item.value !== undefined
      ? { cumulative: sortedData.slice(0, index + 1).reduce((acc, { value }) => acc + value, 0) }
      : {}),
    projectedCumulative: (index + 1) * averageAiSpendPerDay,
  }))

  const dataKey = activeChart === 'total' ? 'cumulative' : 'value'

  const spends: Record<SpendType, { value: number; label: string }> = {
    total: {
      value: totalAiSpend,
      label: 'Total',
    },
    daily: {
      value: averageAiSpendPerDay,
      label: 'Daily Average',
    },
  }

  return (
    <Card>
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP['ai_spend'].name}</CardTitle>
          <CardDescription>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP['ai_spend'].description}</CardDescription>
        </div>
        <div className="flex">
          {Object.keys(spends).map((key) => {
            const typeKey = key as keyof typeof spends
            return (
              <button
                key={key}
                data-active={activeChart === typeKey}
                className="flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                onClick={() => setActiveChart(typeKey)}
              >
                <span className="text-xs text-muted-foreground">{spends[typeKey].label}</span>
                <span className="text-lg font-bold leading-none sm:text-3xl">
                  {formatters.nanodollars(spends[typeKey].value)}
                </span>
              </button>
            )
          })}
        </div>
      </CardHeader>
      <CardContent className="p-2 sm:p-6">
        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
          <AreaChart
            accessibilityLayer
            data={dataWithCumulativeValue}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(8, 10)}
              ticks={generateMonthTicks(selectedMonth)}
            />
            <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => formatters.nanodollars(value)} />
            <ChartTooltip
              cursor={false}
              filterNull={true}
              content={
                <ChartTooltipContent
                  valueFormatter={(value) => {
                    return formatters.nanodollars(value as number)
                  }}
                />
              }
            />
            <defs>
              <linearGradient id="fillValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={`var(--color-${dataKey})`} stopOpacity={0.8} />
                <stop offset="95%" stopColor={`var(--color-${dataKey})`} stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              dataKey={activeChart === 'total' ? 'projectedCumulative' : 'dailyAverage'}
              type="monotone"
              fill="none"
              stroke={`var(--color-${activeChart === 'total' ? 'projectedCumulative' : 'dailyAverage'})`}
              strokeWidth={2}
              strokeDasharray="5 5"
              connectNulls={true}
              stackId="b"
            />
            <Area
              dataKey={dataKey}
              type="bump"
              fill="url(#fillValue)"
              fillOpacity={0.4}
              stroke={`var(--color-${dataKey})`}
              stackId="a"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
