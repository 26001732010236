import { cn } from '~/utils'
import { Badge, Link as ThemeLink } from '@radix-ui/themes'
import { ComponentProps, ReactNode } from 'react'
import { BarList } from '@tremor/react'
import { blue } from '@radix-ui/colors'
import { HoverCard } from '@bpinternal/ui-kit'
import { segregateUsages } from '../utils'

type Props = {
  className?: string
  breakdowns: {
    botId: string
    value: number
    name?: string
  }[]
  topN: number
  valueFormatter?: (value: number) => string
}

export const BotBreakdownLegend = ({ className, breakdowns, valueFormatter, topN, ...props }: Props) => {
  const { usages, remainder } = segregateUsages(breakdowns, topN)

  const otherUsagesData = breakdowns.slice(topN).map(({ value, name, botId }) => ({ value, name: name ?? botId }))

  return (
    <div {...props} className={cn('flex w-full min-w-0 flex-col gap-3', className)}>
      {usages.map(({ name, value }, i) => (
        <UsageLegendItem
          key={name}
          chartColor={(i + 1) as 1 | 2 | 3 | 4 | 5}
          className="w-full"
          label={name}
          badge={valueFormatter ? valueFormatter(value) : `${value}`}
        />
      ))}

      {remainder !== undefined && (
        <>
          <UsageLegendItem
            chartColor={(topN + 1) as 1 | 2 | 3 | 4 | 5}
            label={
              <HoverCard
                trigger={<ThemeLink className="cursor-pointer">Other</ThemeLink>}
                openDelay={100}
                className="w-64"
              >
                <BarList valueFormatter={valueFormatter} data={otherUsagesData} color={blue.blue8} />
              </HoverCard>
            }
            badge={valueFormatter ? valueFormatter(remainder) : `${remainder}`}
          />
        </>
      )}
    </div>
  )
}

type UsageLegendItemProps = {
  chartColor?: 1 | 2 | 3 | 4 | 5
  label?: ReactNode
  badge?: string
} & ComponentProps<'div'>

const chartBackgroundColors = {
  1: 'bg-chart-1',
  2: 'bg-chart-2',
  3: 'bg-chart-3',
  4: 'bg-chart-4',
  5: 'bg-chart-5',
}

const UsageLegendItem = ({ chartColor = 1, label, badge, className, ...props }: UsageLegendItemProps) => {
  return (
    <div className={cn('flex items-center gap-1', className)} {...props}>
      <div className={`${chartBackgroundColors[chartColor]} size-2 flex-none rounded-sm`} />
      <span className="truncate text-sm text-gray-11">{label}</span>
      <Badge color="gray" className="ml-auto w-min justify-self-center">
        {badge}
      </Badge>
    </div>
  )
}
