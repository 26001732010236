import { Card, ThemeColor } from '@bpinternal/ui-kit'
import { Flex, Tooltip, Text, Separator, Badge, Grid } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import type { Issue, IssueEvent } from '@botpress/client'
import { DateTime } from 'luxon'
import { Fragment, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { Boundary, Identifier, Page, IdentifierDropdownMenu, type BreadcrumbPage, Breadcrumbs } from '~/componentsV2'
import { DataListItem, DataListRoot, Tag } from '~/elementsv2'
import { getIssueQueryOptions, listIssueEventsQueryOptions } from '~/queries'
import { createIdentifierStudioUrl, isIdentifier, parseIdentifier, Visibility } from '~/utils'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/issues/$issueId')({
  component: Component,
})

const MAX_EVENTS = 20
function splitAt<T>(arr: T[], index: number): [T[], T[]] {
  return [arr.slice(0, index), arr.slice(index)]
}

function Component() {
  const { workspaceId, botId, issueId } = Route.useParams()

  const { issue } = useSuspenseQuery(getIssueQueryOptions({ botId, workspaceId, issueId })).data
  const { workflowId, nodeId } = issue.groupedData

  const issueEvents = useSuspenseQuery(listIssueEventsQueryOptions({ botId, workspaceId, issueId })).data.sort(
    (a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    }
  )

  const eventGroups: IssueEvent[][] = []
  if (issue.eventsCount <= MAX_EVENTS) {
    // only one group
    eventGroups.push(issueEvents)
  } else {
    // some events were deleted, we need to split into two groups
    const delta = issue.eventsCount - MAX_EVENTS
    const splitIndex = delta < MAX_EVENTS / 2 ? delta : MAX_EVENTS / 2

    const [most_recent, oldest] = splitAt(issueEvents, splitIndex)
    eventGroups.push(most_recent)
    eventGroups.push(oldest)
  }

  const breadcrumbs: BreadcrumbPage[] = [
    {
      name: 'Issues',
      to: '/workspaces/$workspaceId/bots/$botId/issues',
      params: { workspaceId, botId },
      search: {},
    },
    { name: issue.title, params: {}, search: {} },
  ]

  return (
    <Page
      title={<Breadcrumbs pages={breadcrumbs} size={'4'} />}
      actions={<Identifier color={ThemeColor} id={issueId} />}
    >
      <Boundary height={'200px'} loaderSize="6">
        {Object.entries(issue.groupedData).length > 0 && (
          <Flex direction={'column'} gap={'3'} pb={'4'}>
            <Text size={'2'} weight={'medium'}>
              Issue Tags
            </Text>
            <Flex wrap={'wrap'} gap={'2'}>
              {Object.entries(issue.groupedData).map(([key, { raw, pretty }]) => {
                const studioUrl = match({ key })
                  .with({ key: 'workflowId' }, () => {
                    return createIdentifierStudioUrl({ botId, workflowId: raw })
                  })
                  .with({ key: 'nodeId' }, () => {
                    return createIdentifierStudioUrl({ botId, workflowId: workflowId?.raw, nodeId: raw })
                  })
                  .with({ key: 'cardId' }, () => {
                    return createIdentifierStudioUrl({
                      botId,
                      workflowId: workflowId?.raw,
                      nodeId: nodeId?.raw,
                      cardId: raw,
                    })
                  })
                  .otherwise(() => undefined)
                return (
                  <IdentifierDropdownMenu key={key} studioUrl={studioUrl} value={raw}>
                    <Tag clickable label={key} value={pretty ?? raw} />
                  </IdentifierDropdownMenu>
                )
              })}
            </Flex>
          </Flex>
        )}
        <Flex align={'baseline'} gap={'3'} py={'3'}>
          <Text size={'2'} weight={'medium'}>
            Events
          </Text>
          <Badge size={'2'}>{issue.eventsCount}</Badge>
        </Flex>
        <Card className="p-0">
          <Text asChild size={'2'}>
            <Grid gapX={'7'} className="relative grid-cols-[repeat(3,auto),1fr]">
              <Grid align={'center'} p={'2'} className="col-[1/-1] grid-cols-subgrid bg-gray-2 font-medium">
                <Text>Event</Text>
                <Text>Time</Text>
                <Text>Data</Text>
              </Grid>
              {eventGroups.map((events, i) => (
                <Fragment key={i}>
                  {i !== 0 && (
                    <>
                      <Separator size={'4'} className="col-[1/-1]" />
                      <Flex className="col-[1/-1]" py={'4'}>
                        <div className="grow bg-[url('/background/angled-line.svg')] bg-left bg-repeat-x" />
                        <Flex align={'center'} gap={'2'}>
                          <Tooltip content="We keep only the most recent and oldest events">
                            <Badge size={'3'} color="gray" variant="outline">
                              <Text>{issue.eventsCount - MAX_EVENTS} other events</Text>
                            </Badge>
                          </Tooltip>
                        </Flex>
                        <div className="grow bg-[url('/background/angled-line.svg')] bg-left bg-repeat-x" />
                      </Flex>
                    </>
                  )}
                  {events.map((event) => (
                    <Fragment key={event.id}>
                      <Separator size={'4'} className="col-[1/-1]" />
                      <IssueEventRow issueEvent={event} issue={issue} />
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Grid>
          </Text>
        </Card>
      </Boundary>
    </Page>
  )
}

const IssueEventRow = ({ issueEvent, issue }: { issue: Issue; issueEvent: IssueEvent }) => {
  const { botId } = Route.useParams()
  const [expanded, setExpanded] = useState(false)
  const dataContainerRef = useRef<HTMLDivElement>(null)
  const [visibleTag, setVisibleTag] = useState<{ key: string; value: { raw: string; pretty?: string } }[]>([])
  const { workflowId, nodeId } = issueEvent.data
  const specificData = Object.entries(issueEvent.data)
    .map(([k, { raw, pretty }]) => {
      if (k in issue.groupedData) {
        return undefined
      }
      return { key: k, value: { raw, pretty } }
    })
    .filter(Boolean)
    .sort((a, b) => (a.value.raw + a.key).length - (b.value.raw + b.key).length)

  return (
    <>
      <Grid
        p={'2'}
        align={'center'}
        className="col-[1/-1] grid-cols-subgrid hover:cursor-pointer hover:bg-gray-2 has-[.identifier:hover]:bg-inherit"
        onClick={() => {
          setExpanded((prev) => !prev)
        }}
      >
        <Identifier id={issueEvent.id} color="blue" className="identifier w-max max-w-56 truncate" />
        <Text color="gray" size={'1'} wrap={'nowrap'}>
          {DateTime.fromISO(issueEvent.createdAt).toFormat('yyyy-MM-dd HH:mm:ss')}
        </Text>
        <Flex ref={dataContainerRef} overflow={'hidden'} gap={'2'} className="invisible col-start-3 row-start-1 mr-16">
          {specificData.map(({ key: k, value: v }) => (
            <Visibility
              key={k}
              parentRef={dataContainerRef}
              onVisibilityChange={(visible) => {
                if (!visible) {
                  setVisibleTag((prev) => prev.filter((i) => i.key !== k))
                } else {
                  setVisibleTag((prev) => {
                    if (prev.some((i) => i.key === k)) {
                      return prev
                    }
                    return [{ key: k, value: v }, ...prev]
                  })
                }
              }}
            >
              <Tag className="max-w-52" key={k} label={k} value={v.raw ?? v.pretty} />
            </Visibility>
          ))}
        </Flex>
        <Flex gap={'2'} align={'center'} className="col-start-3 row-start-1">
          {visibleTag.map(({ key: k, value: v }) => (
            <Tag className="max-w-52" key={k} label={k} value={v.raw ?? v.pretty} />
          ))}
          <Text size={'1'} wrap={'nowrap'} color="gray">
            + {specificData.length - visibleTag.length} more
          </Text>
        </Flex>
      </Grid>
      {expanded && (
        <>
          <Separator size={'4'} className="col-[1/-1]" />
          <DataListRoot className="col-[1/-1] gap-y-6 px-4 py-4">
            {specificData
              .sort(({ value: a }, { value: b }) => (isIdentifier(a.raw) && !isIdentifier(b.raw) ? -1 : 0))
              .map(({ key, value: data }) => {
                const idPrefix = parseIdentifier(data.raw)
                const studioUrl = match({ key, data })
                  .with({ key: 'workflowId' }, () => {
                    return createIdentifierStudioUrl({ botId, workflowId: data.raw })
                  })
                  .with({ key: 'nodeId' }, () => {
                    return createIdentifierStudioUrl({ botId, workflowId: workflowId?.raw, nodeId: data.raw })
                  })
                  .with({ key: 'cardId' }, () => {
                    return createIdentifierStudioUrl({
                      botId,
                      workflowId: workflowId?.raw,
                      nodeId: nodeId?.raw,
                      cardId: data.raw,
                    })
                  })
                  .otherwise(() => undefined)
                return (
                  <DataListItem key={key} label={key} highContrast>
                    <pre className="-mt-1.5 w-full overflow-auto break-words rounded-md bg-gray-2 p-2 font-mono text-xs">
                      {idPrefix === 'unknown' ? (
                        (data.pretty ?? data.raw)
                      ) : (
                        <>
                          <Identifier
                            studioUrl={studioUrl}
                            prefix={idPrefix}
                            className="w-fit"
                            color={ThemeColor}
                            id={data.raw}
                          />
                          {(data.pretty ?? data.raw).replace(data.raw, '')}
                        </>
                      )}
                    </pre>
                  </DataListItem>
                )
              })}
          </DataListRoot>
        </>
      )}
    </>
  )
}
