import type { Integration } from '@botpress/client'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React, { ComponentProps, PropsWithChildren } from 'react'
import { Spinner } from '../../../elements'
import { useUpdateBot } from '../../../hooks'
import { BotSelector } from './BotSelector'
import { SuccessCheckmark } from './SuccessCheckmark'
import { BotSummary } from '~/features/bots/types'
import { openConfigureBotIntegrationStudioTab } from '~/features/integrations/services/utils'
import { Popover } from '@radix-ui/themes'
import { Button } from '@bpinternal/ui-kit'

type BotSelectorPopoverProps = {
  currentIntegration: Integration
  workspaceId: string
} & PropsWithChildren<ComponentProps<'div'>>

export const PrivateIntegrationInstallPopover = ({
  children,
  currentIntegration,
  workspaceId,
  ...props
}: BotSelectorPopoverProps) => {
  const [selectedBot, setSelectedBot] = React.useState<BotSummary>()
  const [formState, setFormState] = React.useState<'bot' | 'done'>('bot')

  const { mutate: updateBot, isPending: isUpdatingBot } = useUpdateBot()

  const addIntegration = () => {
    if (!selectedBot) {
      return
    }

    setFormState('done')

    updateBot({
      workspaceId,
      id: selectedBot.id,
      integrations: {
        [currentIntegration.id]: {
          enabled: false,
          configuration: {},
        },
      },
      onError: () => {
        setFormState('bot')
      },
    })
  }

  return (
    <Popover.Root>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content align="end" asChild>
        <div {...props} className="flex w-[28rem] flex-col p-4">
          {formState === 'bot' && (
            <>
              <BotSelector
                selectedBot={selectedBot}
                currentIntegration={currentIntegration}
                currentWorkspaceId={workspaceId}
                setSelectedBot={setSelectedBot}
              />
              <Button size="1" disabled={!selectedBot} className="w-fit self-end" onClick={addIntegration}>
                Add to bot
              </Button>
            </>
          )}
          {formState === 'done' &&
            (isUpdatingBot ? (
              <Spinner className="self-center" />
            ) : (
              <>
                <div className="my-4 mb-6 h-12 w-12 self-center">
                  <SuccessCheckmark />
                </div>
                <h3 className="mb-2 self-center font-semibold text-gray-12">Integration successfully installed!</h3>
                <p className="mb-12 self-center text-sm text-gray-11">
                  You can now go back to the dashboard and configure it.
                </p>
                <div className="flex justify-end gap-2">
                  <Button
                    onClick={() => openConfigureBotIntegrationStudioTab(selectedBot?.id ?? '', currentIntegration.id)}
                    trailing={<ArrowRightIcon className="h-4" />}
                    size="1"
                  >
                    Configure Integration
                  </Button>
                </div>
              </>
            ))}
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}
