import { queryOptions } from '@tanstack/react-query'
import { cacheDuration } from '../shared'
import { useQueryKeys } from '../hooks'
import { getApiClient } from '../client'
import { ClientOutputs } from '@botpress/client'

export type BotAnalyticsQueryOptions = {
  botId: string
  workspaceId: string
  startDate: Date
  endDate: Date
}

export const analyticsOverviewQueryOptions = ({ botId, workspaceId, startDate, endDate }: BotAnalyticsQueryOptions) => {
  const { getBotAnalyticsOverview } = useQueryKeys()

  startDate.setSeconds(0, 0)
  endDate.setSeconds(0, 0)
  return queryOptions({
    staleTime: cacheDuration.long,
    queryKey: getBotAnalyticsOverview({
      botId,
      workspaceId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }),
    queryFn: async (): Promise<ClientOutputs['getBotAnalytics']> => {
      return getApiClient(workspaceId).getBotAnalytics({
        id: botId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    },
    meta: {
      suppressError: true,
    },
  })
}
