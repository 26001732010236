import { createFileRoute, Link, Outlet } from '@tanstack/react-router'
import { Boundary, Page } from '~/componentsV2'
import { Button } from '@bpinternal/ui-kit-next'
import { Plus } from 'lucide-react'

export const Route = createFileRoute('/workspaces/$workspaceId/usage')({
  component: Component,
})

function Component() {
  const { workspaceId } = Route.useParams()

  return (
    <Page
      title="Usage"
      actions={
        <Link to={'/workspaces/$workspaceId/billing'} params={{ workspaceId }}>
          <Button>
            <Plus />
            Increase Limits
          </Button>
        </Link>
      }
    >
      <Boundary>
        {/* TODO: Add Skeleton loading screen */}
        <Outlet />
      </Boundary>
    </Page>
  )
}
