import { createFileRoute } from '@tanstack/react-router'
import { Flex, Grid, Popover, Separator, Text } from '@radix-ui/themes'
import { queryOptions, useQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import { botQueryOptions, listUsersInfiniteQuery } from '~/queries'
import { Avatar, DataListItem, DataListRoot, EmptyState, Icon, Link, Spinner } from '~/elementsv2'
import { Boundary, Identifier, QueryParamBuilder, type FilterMenuInput, Page } from '~/componentsV2'
import { Fragment, useMemo } from 'react'
import { z } from 'zod'
import type { ClientOutputs } from '@botpress/client'
import { DateTime } from 'luxon'
import EmptyStateIcon from '~/assets/programming-04.svg?react'
import { useInfiniteScroll } from '~/hooks'
import { Button, Card } from '@bpinternal/ui-kit'
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2'
import { getQueryKey, queryFunctions } from '~/services'
import { FILTER_SEPARATOR } from '~/componentsV2/QueryParamBuilder/parseParams'
import { useIntegrations } from '~/hooks/integrations/useIntegrations'

const usersSearchParamsSchema = z.object({
  conversationId: z
    .string()
    .optional()
    .catch(() => undefined),
  userId: z
    .string()
    .optional()
    .catch(() => undefined),
  tags: z
    .record(z.string())
    .optional()
    .catch(() => undefined),
})

const UsersPage = () => {
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data

  const integrationTags = useIntegrations({
    workspaceId,
    integrationIds: Object.values(bot.integrations).map((i) => i.id),
  })
    .filter((i) => Object.keys(i.user.tags).length > 0)
    .map<FilterMenuInput>((i) => ({
      name: i.name,
      icon: <Icon size="2" variant={'surface'} color={'gray'} icon={(props) => <img src={i.iconUrl} {...props} />} />,
      value: Object.keys(i.user.tags).map<FilterMenuInput>((t) => ({
        name: t,
        paramName: `tags`,
        type: 'object',
        value: `${i.name}:${t}${FILTER_SEPARATOR}`,
      })),
    }))

  const filters: FilterMenuInput[] = [
    { name: 'Conversation', paramName: 'conversationId', type: 'string' },
    { name: 'User', paramName: 'userId', type: 'string' },
    { name: 'Integration Tags', value: integrationTags },
    ...Object.keys(bot.user.tags)
      .filter((t) => !['upstream', 'downstream'].includes(t))
      .map<FilterMenuInput>((t) => ({
        name: t,
        paramName: 'tags',
        type: 'object',
        value: `${t}${FILTER_SEPARATOR}`,
      })),
  ]

  return (
    <Page title="Users">
      <Flex direction={'column'} gap={'4'}>
        <QueryParamBuilder filters={filters} />
        <Boundary loaderSize={'6'}>
          <UsersList />
        </Boundary>
      </Flex>
    </Page>
  )
}

const UsersList = () => {
  const { workspaceId, botId } = Route.useParams()
  const { conversationId, tags, userId } = Route.useSearch()

  const {
    data: fetchedUsers,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useSuspenseInfiniteQuery(listUsersInfiniteQuery({ botId, workspaceId, tags, conversationId }))
  const bottomRef = useInfiniteScroll(fetchNextPage, hasNextPage)

  const { data: fetchedUser } = useQuery(
    queryOptions({
      queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/$userId_', {
        workspaceId,
        botId,
        userId: userId ?? '',
      }),
      queryFn: async () =>
        queryFunctions['workspaces_/$workspaceId_/bots_/$botId_/$userId_']({
          workspaceId,
          botId,
          userId: userId ?? '',
        }),
      enabled: !!userId,
    })
  )

  const users = useMemo(
    () => (fetchedUser?.user ? [fetchedUser.user] : fetchedUsers.pages.flatMap((p) => p.users)),
    [fetchedUsers.pages, fetchedUser]
  )

  return (
    <>
      <Card className="p-0">
        <Text asChild size={'2'}>
          <Grid gapX={'7'} className="relative grid-cols-[repeat(2,auto),1fr,1fr,1fr]">
            <Grid align={'center'} p={'2'} className="col-[1/-1] grid-cols-subgrid bg-gray-2 font-medium">
              <div />
              <Text>User Identifier</Text>
              <Text>Name</Text>
              <Text>Tags</Text>
              <Text>Last Modified</Text>
            </Grid>
            {users.length === 0 ? (
              <>
                <Separator size={'4'} className="col-[1/-1]" />
                <EmptyState
                  iconSize={6}
                  icon={EmptyStateIcon}
                  className="col-[1/-1] py-16"
                  title="Your bot has no users yet"
                  description="Users will appear here once you start interacting with your bot."
                  primaryAction={
                    <Link to="/workspaces/$workspaceId/bots/$botId/webchat" params={{ workspaceId, botId }}>
                      <Button>Start chatting!</Button>
                    </Link>
                  }
                />
              </>
            ) : (
              users.map((user) => (
                <Fragment key={user.id}>
                  <Separator size={'4'} className="col-[1/-1]" />
                  <UserRow user={user} />
                </Fragment>
              ))
            )}
          </Grid>
        </Text>
      </Card>
      <div ref={bottomRef} />
      {isFetching && (
        <Flex className="w-full items-center justify-center">
          <Spinner size="6" />
        </Flex>
      )}
    </>
  )
}

const UserRow = ({ user }: { user: ClientOutputs['listUsers']['users'][number] }) => {
  const navigate = Route.useNavigate()
  const { id, updatedAt, name = 'Unknown User', pictureUrl, tags } = user
  const nTags = Object.keys(tags).length
  const tagsLabel = nTags === 0 ? 'No tags' : `${nTags} Tag${nTags > 1 ? 's' : ''}`

  return (
    <Grid p={'2'} align={'center'} className="col-[1/-1] grid-cols-subgrid">
      <Avatar name={name} pictureUrl={pictureUrl} size={'2'} />
      <Identifier id={id} className="w-fit" />
      <Text color="gray">{name}</Text>
      {nTags === 0 ? (
        <Text className="" size={'1'}>
          No tags
        </Text>
      ) : (
        <Popover.Root>
          <Popover.Trigger>
            <Button variant="ghost" size="1" className="w-10" onClick={(e) => e.stopPropagation()}>
              {tagsLabel}
            </Button>
          </Popover.Trigger>
          <Popover.Content>
            <DataListRoot size={'1'}>
              {Object.entries(tags).map(([tag, value]) => (
                <DataListItem highContrast key={tag} label={tag} className="font-semibold">
                  <Button
                    size={'1'}
                    variant="ghost"
                    highContrast
                    color="gray"
                    trailing={<Icon size="1" icon={HiOutlineMagnifyingGlass} />}
                    onClick={() => {
                      void navigate({
                        search: (prev) => ({ ...prev, tags: { ...prev?.tags, [tag]: value } }),
                      })
                    }}
                  >
                    {value}
                  </Button>
                </DataListItem>
              ))}
            </DataListRoot>
          </Popover.Content>
        </Popover.Root>
      )}
      <Text color="gray">{DateTime.fromISO(updatedAt).toRelative()}</Text>
    </Grid>
  )
}

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/users')({
  validateSearch: usersSearchParamsSchema,
  component: () => (
    <Boundary height={'200px'} loaderSize="6">
      <UsersPage />
    </Boundary>
  ),
})
